import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import useSiteMetadata from '../hooks/use-site-config'
import useSiteImages from '../hooks/use-site-images'
import TagList from './TagList'
import Flag from './Flag/Flag'
import { ReadingTime, Bull } from './Commons'
import { colors, media } from '../tokens'
import NewsPostCard from '../components/NewsPostCard'

const PreviewContainer = styled.aside`
  display: flex;
  flex-wrap: wrap;
  max-width: 770px;
  width: 80%;
  margin: 0px auto 30px auto;
  top: 20px;
  position: relative;

  @media ${media.maxLarge}  {
    width: 100%;
    padding: 25px;
  }
`

const PrevNextPost = props => {
  const { previous, next } = props
  const articles = [previous, next].filter(i => i).map(item => ({ node: item }))

  return (
    <Fragment>
      <PreviewContainer>
        {articles.map((article, i) => {
          return (
              <NewsPostCard article={article} index={i}></NewsPostCard>
          )
        })}
      </PreviewContainer>
    </Fragment>
  )
}

export default PrevNextPost
