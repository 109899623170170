import React from 'react'
import { graphql } from 'gatsby'
import LayoutGeneral from '../components/Layouts'
import Wrapper from '../components/Wrapper'
import Hero from '../components/Hero'
import Article from '../components/Article'
import PrevNextPost from '../components/PrevNextPost'
import SEO from '../components/SEO'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.post
    const { previous, next } = this.props.pageContext
    //console.log(previous)
    //console.log(next)

    return (
      <LayoutGeneral location={this.props.location}>
        <SEO
          title={post.frontmatter.title}
          description={post.excerpt}
          cover={post.frontmatter.coverPublic || post.frontmatter.cover && post.frontmatter.cover.publicURL}
          imageShare={
            post.frontmatter.imageShare && post.frontmatter.imageShare.publicURL
          }
          lang={post.frontmatter.language}
          path={post.fields.slug}
          isBlogPost
        />

        {/* <Hero
          heroImg={post.frontmatter.coverPublic || post.frontmatter.cover && post.frontmatter.cover.publicURL}
          title={post.frontmatter.title}
        /> */}

        <Wrapper>
          <Article post={post} />
        </Wrapper>

        {/* <Wrapper> */}
        <PrevNextPost previous={previous} next={next} />
        {/* </Wrapper> */}
      </LayoutGeneral>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    post: mdx(fields: { slug: { eq: $slug } }) {
      excerpt
      timeToRead
      body
      fields{
        slug
      }
      frontmatter {
        title
        date
        slug
        language
        tags
        cover {
          publicURL
        }
        coverPublic
        imageShare {
          publicURL
        }
        translations {
          language
          link
        }
      }
    }
  }
`